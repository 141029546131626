var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-right is-hidden-mobile"},[(_vm.expenseDetail)?_c('div',[_c('div',{staticClass:"p-5"},[_c('div',[_c('div',{staticClass:"columns mb-0"},[_c('div',{staticClass:"column pb-0"},[_c('p',{staticClass:"text-small"},[_vm._v(" Added on "+_vm._s(_vm._f("date")(_vm.expenseDetail.createdAt))+" ")]),_c('p',{staticClass:"text-small"},[_vm._v(" Last updated on "+_vm._s(_vm._f("date")(_vm.expenseDetail.updatedAt))+" ")])]),_c('div',{staticClass:"column has-text-right pb-0 is-narrow"},[_c('div',[_c('button',{staticClass:"button button-round",on:{"click":() => {
                    this.$emit('sidebar-closed');
                  }}},[_c('i',{staticClass:"mdi mdi-close"})])])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(this.tripId)?_c('router-link',{attrs:{"to":{
                name: 'TripDetail',
                query: {
                  vehicleId: this.vehicleId,
                  tripId: this.tripId
                }
              }}},[_c('b-button',{staticClass:"mt-2",attrs:{"rounded":"","outlined":"","size":"is-small"}},[_c('i',{staticClass:"mdi mdi-eye"}),_c('span',{staticClass:"ml-1"},[_vm._v(" View Trip")])])],1):_vm._e()],1),_c('div',{staticClass:"column is-narrow mt-3"},[_c('a',[_c('i',{staticClass:"mdi mdi-pencil has-text-info pr-1",on:{"click":() => {
                    this.$emit('openUpdateExpenseModal');
                  }}})]),_c('a',[_c('i',{staticClass:"mdi mdi-delete has-text-danger pr-1",on:{"click":() => {
                    this.$emit('openDeleteExpenseModal');
                  }}})])])])]),_c('hr'),_c('div',[_c('p',{staticClass:"text-small"},[_vm._v("Expense Type")]),_c('p',[_vm._v(_vm._s(_vm._f("formatNullvalue")(_vm._f("capitalize")(_vm.expenseDetail.expenseType))))]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Merchant")]),_c('p',[_vm._v(_vm._s(_vm._f("formatNullvalue")(_vm.expenseDetail.merchant)))]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Price")]),_c('p',[_vm._v("$ "+_vm._s(_vm.expenseDetail.price))]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Date")]),_c('p',[_vm._v(_vm._s(_vm._f("date")(_vm.expenseDetail.dateTime,"medium")))]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Tax Percentage")]),_c('p',[_vm._v(_vm._s(_vm._f("formatNullvalue")(_vm.expenseDetail.taxPercent)))]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Location")]),_c('p',[_vm._v(_vm._s(_vm._f("formatNullvalue")(_vm.expenseDetail.location)))]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Comments")]),_c('p',[_vm._v(_vm._s(_vm._f("formatNullvalue")(_vm.expenseDetail.comment)))]),_c('p',{staticClass:"text-small mt-4"},[_vm._v("Attachments")]),_c('div',{staticClass:"mt-4"},[(_vm.expenseDetail.attachments)?_c('div',_vm._l((_vm.expenseDetail.attachments),function(attachment,index){return _c('div',{key:index},[_c('p',[_c('i',{staticClass:"mdi mdi-paperclip pr-2"}),_c('a',{attrs:{"href":attachment.url,"target":"_blank"}},[_vm._v(_vm._s(attachment.fileName))])])])}),0):_c('div',[_vm._v("-")])])])])]):_c('div',[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body has-text-centered sidebar-right-empty"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/img/sidebar-right-idle-icon.svg")}}),_c('p',[_vm._v("Select an expense to view details")])])])])
}]

export { render, staticRenderFns }