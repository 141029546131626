
import router from "@/router";
import Vue from "vue";
import charts from "@/services/chartService";
import notification from "@/services/notificationService";
import VehicleDetailRightSidebar from "@/components/VehicleDetailRightSidebar.vue";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
import { VehicleExpense } from "@/shared/models";
import find from "lodash/find";
import sharedData from "@/shared/data";

export default Vue.extend({
  name: "Vehicle",
  components: { VehicleDetailRightSidebar, NoTenantSelectedMessage },
  data() {
    return {
      charts: charts,
      selectedPeriod: "today",
      overviewDay: undefined as undefined | any,
      overviewWeek: undefined as undefined | any,
      overviewMonth: undefined as undefined | any,
      selectedTab: "details" as undefined | string,
      tableProps: {
        isPaginated: true,
        isPaginationSimple: false,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 10
      },
      selected: undefined as undefined | VehicleExpense,
      editVehicleToggle: false,
      editVehicleDetail: {
        vehicleType: undefined as undefined | string,
        nickname: undefined as undefined | string,
        make: undefined as undefined | string,
        model: undefined as undefined | string,
        registrationNo: undefined as undefined | string,
        donglePlugStatus: undefined as undefined | boolean,
        dongleId: undefined as undefined | string,
        milTrackerActive: undefined as undefined | boolean,
        odomMileageActive: undefined as undefined | boolean,
        odometerMileageInKm: undefined as undefined | boolean,
        bookingActive: undefined as undefined | boolean,
        routeHistActive: undefined as undefined | boolean,
        fleetCategory: undefined as undefined | { id: string }
      },
      expenseAttachments: [] as File[],
      pendingUploads: 0,
      uploadInProgress: false,
      addDocumentToggle: false,
      addExpenseToggle: false,
      updateExpenseToggle: false,
      deleteExpenseToggle: false,
      addExpenseDetail: {
        expenseType: undefined as undefined | string,
        merchant: undefined as undefined | string,
        price: undefined as undefined | number,
        dateTime: undefined as undefined | string,
        recurring: undefined as undefined | boolean,
        taxPercent: undefined as undefined | number,
        location: undefined as undefined | string,
        comment: undefined as undefined | string,
        tripId: undefined as undefined | string
      },
      updateExpenseDetail: undefined as undefined | VehicleExpense,
      vehicleList: sharedData.vehicleModels
    };
  },
  computed: {
    vehicleDetail() {
      return this.$store.state.vehicleDetail;
    },
    vehicleExpense(): VehicleExpense[] {
      return this.$store.state.vehicleExpense;
    },
    expenseDetail(): undefined | VehicleExpense {
      return this.$store.state.expenseDetail;
    },
    attachmentURLs():
      | undefined
      | { url: string; fileKey: string; fileName: string }[] {
      return this.$store.state.attachmentURLS;
    },
    expenseType() {
      return sharedData.expenseType;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    fleetCategories(): any {
      return this.$store.state.fleetCategories;
    }
  },
  watch: {
    selectedTenant: function() {
      this.initializeView();
    },
    selectedTab: function() {
      this.removeSelection();
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          selectedTab: this.selectedTab
        }
      });
    },
    vehicleExpense: function() {
      if (this.$route.query.expenseId) {
        const selectedExpense = find(this.vehicleExpense, {
          id: this.$route.query.expenseId
        } as VehicleExpense);
        this.selected = selectedExpense;
      }
    }
  },
  updated() {
    this.renderGraphs();
  },
  async created() {
    this.initializeView();
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        this.$store.dispatch("getFleetCategories", {
          selectedTenant: this.selectedTenant
        });
        if (this.$route.query.vehicleId) {
          if (this.$route.query.selectedTab) {
            this.selectedTab = this.$route.query.selectedTab.toString();
          }
          this.$store.dispatch("getVehicleDetail", {
            selectedTenant: this.selectedTenant,
            vehicleId: this.$route.query.vehicleId
          });
        } else {
          this.$router.replace({
            name: "Vehicles"
          });
        }
      }
    },
    renderGraphs() {
      if (this.overviewDay && this.overviewWeek && this.overviewMonth) {
        const barGraphOptions = {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          responsive: true
        };
        if (this.selectedPeriod === "today") {
          this.charts.createChart({
            data: this.overviewDay.expensesByCategory,
            type: "bar",
            label: "Amount in EUR",
            div: this.$refs["expensesDay"],
            options: barGraphOptions
          });
          this.charts.createChart({
            data: this.overviewDay.tripsByCategory,
            type: "doughnut",
            label: "Amount in EUR",
            div: this.$refs["tripsDay"],
            options: {}
          });
        } else if (this.selectedPeriod === "week") {
          this.charts.createChart({
            data: this.overviewWeek.expensesByCategory,
            type: "bar",
            label: "Amount in EUR",
            div: this.$refs["expensesWeek"],
            options: barGraphOptions
          });
          this.charts.createChart({
            data: this.overviewWeek.tripsByCategory,
            type: "doughnut",
            label: "Amount in EUR",
            div: this.$refs["tripsWeek"],
            options: {}
          });
        } else if (this.selectedPeriod === "month") {
          this.charts.createChart({
            data: this.overviewMonth.expensesByCategory,
            type: "bar",
            label: "Amount in EUR",
            div: this.$refs["expensesMonth"],
            options: barGraphOptions
          });
          this.charts.createChart({
            data: this.overviewMonth.tripsByCategory,
            type: "doughnut",
            label: "Amount in EUR",
            div: this.$refs["tripsMonth"],
            options: {}
          });
        }
      }
    },
    showDetail(payload: VehicleExpense) {
      if (payload.trip) {
        router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            expenseId: payload.id,
            tripId: payload.trip.id
          }
        });
      } else {
        router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            expenseId: payload.id,
            tripId: undefined
          }
        });
      }
    },
    removeSelection() {
      this.selected = undefined;
      this.$store.dispatch("setExpenseDetail", undefined);
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          expenseId: undefined
        }
      });
    },
    validateData(data: Record<string, number>) {
      return Object.values(data).reduce((a, b) => a + b, 0) === 0;
    },
    updateVehicle(id: string) {
      const request = {
        vehicleType: this.editVehicleDetail.vehicleType,
        nickname: this.editVehicleDetail.nickname,
        make: this.editVehicleDetail.make,
        model: this.editVehicleDetail.model,
        registrationNo: this.editVehicleDetail.registrationNo,
        donglePlugStatus: this.editVehicleDetail.donglePlugStatus,
        dongleId: this.editVehicleDetail.dongleId,
        odomMileageInKm: this.editVehicleDetail.odomMileageInKm,
        fleetCategory: this.editVehicleDetail.fleetCategory
      };
      this.$store.dispatch("updateVehicleDetail", {
        selectedTenant: this.selectedTenant,
        vehicleId: this.$route.query.vehicleId,
        request: request
      });
      this.editVehicleToggle = false;
    },
    openEditVehicleModal() {
      this.editVehicleToggle = true;
      this.editVehicleDetail = {
        vehicleType: this.vehicleDetail.vehicleType,
        nickname: this.vehicleDetail.nickname,
        make: this.vehicleDetail.make,
        model: this.vehicleDetail.model,
        registrationNo: this.vehicleDetail.registrationNo,
        donglePlugStatus: this.vehicleDetail.donglePlugStatus,
        dongleId: this.vehicleDetail.dongleId,
        odometerMileageInKm: this.vehicleDetail.odometerMileageInKm,
        fleetCategory: { id: this.vehicleDetail?.fleetCategory?.id }
      };
    },
    openAddDocumentModal() {
      this.uploadInProgress = false;
      this.addDocumentToggle = true;
      this.expenseAttachments = [];
    },
    openAddExpenseModal() {
      this.removeSelection();
      this.uploadInProgress = false;
      this.addExpenseToggle = true;
      this.expenseAttachments = [];
    },
    async openUpdateExpenseModal() {
      this.uploadInProgress = false;
      await this.getExpense();
      this.updateExpenseDetail = Object.assign({}, this.expenseDetail);
      if (this.updateExpenseDetail && this.expenseDetail?.dateTime) {
        this.updateExpenseDetail.dateTime = new Date(
          this.expenseDetail.dateTime
        );
      }
      this.expenseAttachments = [];
      this.updateExpenseToggle = true;
    },
    async openDeleteExpenseModal(expenseID: string) {
      await this.getExpense();
      this.deleteExpenseToggle = true;
    },
    async updateExpense() {
      if (
        this.expenseDetail &&
        this.updateExpenseDetail &&
        this.vehicleDetail
      ) {
        // add current attachments to send to attachExpenses() function
        const currentAttachments = this.updateExpenseDetail.attachments;
        if (this.vehicleDetail && this.expenseDetail) {
          // attachExpenses() handles current attachments with the selected ones
          // so that the updated expense has all the attachments
          await this.attachExpenses(
            this.vehicleDetail.id,
            this.expenseDetail.id,
            currentAttachments
          );
        }
        const request = this.updateExpenseDetail;

        delete request.attachmentFileKeys;
        delete request.attachments;
        // update details of expense via vuex
        await this.$store
          .dispatch("updateExpense", {
            vehicleId: this.vehicleDetail.id,
            expenseId: this.expenseDetail.id,
            updatedExpense: request
          })
          .then(() => {
            this.updateExpenseToggle = false;
            // fetch expenses again to render the new updated values
            this.$store.dispatch(
              "getVehicleExpense",
              this.$route.query.vehicleId
            );
            this.getExpense();
          });
      }
    },
    async getExpense() {
      if (this.expenseDetail) {
        await this.$store.dispatch("getExpenseDetail", {
          vehicleId: this.vehicleDetail.id,
          expenseId: this.expenseDetail.id
        });
      }
    },
    async attachExpenses(
      vehicleId: string,
      expenseId: string | undefined,
      // To store already attached files on Expenses
      currentAttachments = undefined as
        | undefined
        | [{ fileKey: string; url: string }],
      type = "expense"
    ) {
      this.uploadInProgress = true;
      // Initialize empty arrays to prepare data to be send to server
      const attachmentNames: string[] = [];
      const attachmentKeys: string[] = [];
      if (this.expenseAttachments) {
        // Populating attachments name
        for (let i = 0; i < this.expenseAttachments.length; i++) {
          attachmentNames.push(this.expenseAttachments[i].name);
        }
      }
      // If new attachments are added
      if (attachmentNames.length > 0) {
        // Get Upload URLs
        try {
          await this.$store
            .dispatch("getAttachmentURLs", {
              vehicleId: vehicleId,
              attachmentNames: attachmentNames,
              type
            })
            .then(async () => {
              if (this.attachmentURLs && this.expenseAttachments) {
                const attachmentsCount = this.attachmentURLs.length;
                this.pendingUploads = attachmentsCount;
                // Upload attached file to Presigned URL
                let uploadSuccess = true;
                const promises = [];
                for (let i = 0; i < attachmentsCount; i++) {
                  attachmentKeys.push(this.attachmentURLs[i].fileKey);
                  promises.push(
                    // uploading one attachment at a time
                    this.$store.dispatch("uploadAttachment", {
                      attachment: this.expenseAttachments[i],
                      url: this.attachmentURLs[i].url
                    })
                  );
                }
                await Promise.all(promises)
                  .then(res => {
                    // If all Files Uploaded
                    this.uploadInProgress = false;
                  })
                  .catch(err => {
                    notification.error(err.message);
                    // Error in uploading any of the file to Presigned URL
                    this.uploadInProgress = false;
                    uploadSuccess = false;
                    this.pendingUploads = 0;
                  });

                if (uploadSuccess) {
                  // If expense has existing attachments
                  if (currentAttachments) {
                    for (let i = 0; i < currentAttachments.length; i++) {
                      // Concatenate existing and new attachments
                      attachmentKeys.push(currentAttachments[i].fileKey);
                    }
                  }
                  if (type === "expense") {
                    // Adding attachment to expense.
                    this.$store
                      .dispatch("addExpenseAttachment", {
                        vehicleId: vehicleId,
                        expenseId: expenseId,
                        attachmentKeys: attachmentKeys
                      })
                      .catch(err => {
                        this.pendingUploads = 0;
                      });
                  } else if (type === "vehicle") {
                    // Adding attachment to vehicle.
                    this.$store
                      .dispatch("addVehicleAttachment", {
                        vehicleId: vehicleId,
                        attachmentKeys: attachmentKeys
                      })
                      .catch(err => {
                        this.pendingUploads = 0;
                      });
                  }
                }
              }
            });
        } catch (err) {
          // Error while requesting Presigned URL for attachments
          notification.error(err.data.message);
        }
      }
      // If no new attachments were added then (else)
      else {
        // If some attachments were already attached to the expense
        // or removed from the expense
        if (currentAttachments && currentAttachments.length > 0) {
          for (let i = 0; i < currentAttachments.length; i++) {
            // Concatenate existing attachemnts
            attachmentKeys.push(currentAttachments[i].fileKey);
          }
        }
        this.$store
          // Add attachment keys to the expense
          .dispatch("addExpenseAttachment", {
            vehicleId: vehicleId,
            expenseId: expenseId,
            attachmentKeys: attachmentKeys
          })
          .then(() => {
            this.uploadInProgress = false;
          })
          .catch(err => {
            this.uploadInProgress = false;
            this.pendingUploads = 0;
          });
      }
    },
    dropSelectedExpenseAttachment(index: number) {
      if (this.expenseAttachments) {
        this.expenseAttachments.splice(index, 1);
      }
    },
    dropCurrentExpenseAttachment(index: number) {
      this.updateExpenseDetail?.attachments?.splice(index, 1);
    },
    async addDocument() {
      if (this.vehicleDetail) {
        try {
          // Check if there are attachements to be added
          if (this.expenseAttachments != []) {
            if (this.vehicleDetail) {
              // add current attachments to send to attachExpenses() function
              const currentAttachments = this.vehicleDetail.attachments;

              // if attachments are to be added
              await this.attachExpenses(
                this.vehicleDetail.id,
                undefined,
                currentAttachments,
                "vehicle"
              ).then(() => {
                notification.success("Document(s) Added");
              });
            }
          }
          // hide the modal
          this.addDocumentToggle = false;
          // fetch expenses again to update the displayed list
          this.$store.dispatch("getVehicleDetail", this.$route.query.vehicleId);
        } catch (err) {
          notification.error(err.data.message);
        }
      }
    },
    async addExpense() {
      if (this.vehicleDetail) {
        try {
          // dispatch action to add expense via vuex
          await this.$store
            .dispatch("addExpense", {
              vehicleId: this.vehicleDetail.id,
              expenseDetail: this.addExpenseDetail
            })
            .then(async () => {
              // when expense is added check if there are attachements to be added with it
              if (this.expenseAttachments != []) {
                if (this.vehicleDetail && this.expenseDetail)
                  // if attachments are to be added
                  await this.attachExpenses(
                    this.vehicleDetail.id,
                    this.expenseDetail.id
                  );
              }
              notification.success("Expense added");
              // clear addExpenseDetail for next expense addition
              this.addExpenseDetail = {
                expenseType: undefined,
                merchant: undefined,
                price: undefined,
                dateTime: undefined,
                recurring: undefined,
                taxPercent: undefined,
                location: undefined,
                comment: undefined,
                tripId: undefined
              };
              // hide the modal
              this.addExpenseToggle = false;
              // fetch expenses again to update the displayed list
              this.$store.dispatch(
                "getVehicleExpense",
                this.$route.query.vehicleId
              );
            });
        } catch (err) {
          notification.error(err.data.message);
        }
      }
    },
    async deleteExpense() {
      if (this.expenseDetail && this.vehicleDetail) {
        const vehicleId = this.vehicleDetail.id;
        const expenseId = this.expenseDetail.id;
        await this.$store
          .dispatch("deleteExpense", {
            vehicleId: vehicleId,
            expenseId: expenseId
          })
          .then(() => {
            router.replace({
              path: this.$route.path,
              query: {
                ...this.$route.query,
                expenseId: undefined
              }
            });
            this.deleteExpenseToggle = false;
            this.$store.dispatch(
              "getVehicleExpense",
              this.$route.query.vehicleId
            );
          });
      }
    }
  }
});
