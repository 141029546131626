
import Vue from "vue";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
import store from "@/store";

export default Vue.extend({
  name: "VehicleDetailRightSidebar",
  props: {
    vehicleId: String,
    expenseId: String,
    tripId: String
  },
  computed: {
    expenseDetail() {
      return store.state.expenseDetail;
    }
  },
  created() {
    this.getExpenseDetail();
  },
  watch: {
    expenseId: function() {
      if (!this.expenseId) {
        this.$store.dispatch("setExpenseDetail", undefined);
      } else {
        // watching expenseId value to update expenseDetail on value change
        this.getExpenseDetail();
      }
    }
  },
  methods: {
    getExpenseDetail() {
      if (!this.$route.query.vehicleId) {
        notification.warning("No vehicle selected");
      } else if (this.$route.query.vehicleId && this.$route.query.expenseId) {
        this.$store.dispatch("getExpenseDetail", {
          vehicleId: this.$route.query.vehicleId,
          expenseId: this.$route.query.expenseId
        });
      }
    }
  }
});
